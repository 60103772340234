<template>
  <div>
    <div>
      <h1 class="title has-text-centered">Introduction Test</h1>
    </div>
    <div>
      <div class="columns is-12">
        <div class="column is-2">
          <b>Introduction</b>
        </div>
        <div class="column is-10">
          <div v-html="data.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
