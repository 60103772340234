<template>
  <div>
    <!-- For title assessment -->
    <h1 class="title has-text-centered">Question Test</h1>
    <h1 class="title is-size-4"><b>CASE STUDY</b></h1>
    <span class="columns is-multiline">
      <div class="column is-12">
        <div class="columns is-12 section-type">
          <div class="column is-2">
            <b>Question</b>
          </div>
          <div class="column is-10">
            {{ data.section[0].question }}
          </div>
        </div>
        <div class="columns is-12">
          <div class="column is-2">
            <b>Content</b>
          </div>
          <div class="column is-10">
            <div v-html="data.section[0].content"></div>
          </div>
        </div>
      </div>
      <div
        class="column is-12 subsection-type"
        v-for="(subsection, index) in data.subsection"
        :key="index"
      >
        <div class="columns is-12  section-type">
          <div class="column is-2">
            <b>Title</b>
          </div>
          <div class="column is-10">
            {{ subsection.title }}
          </div>
        </div>
        <div class="columns is-12">
          <div class="column is-2">
            <b>Content</b>
          </div>
          <div class="column is-10">
            <div v-html="subsection.question"></div>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
<style>
.section-type {
  margin-bottom: 0 !important;
}

.subsection-type {
  padding-top: 1% !important;
  margin-bottom: 1% !important;
}
</style>
