<template>
  <section v-bind:class="{ main: true, index: activeStep <= 2 }">
    <div class="columns">
      <div class="column bar">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <router-link :to="{ name: `caseStudy` }">
                <h3 class="is-size-4">CASE STUDY</h3>
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: `/case-study/show/${caseStudyId}` }">
                <h3 class="is-size-4">DETAIL</h3>
              </router-link>
            </li>
            <li>
              <h3 class="is-size-4 breadcrumb-item">DETAIL SECTION</h3>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column bar">
        <b-field grouped position="is-right">
          <router-link :to="{ name: `editCaseStudySection` }">
            <b-button class="is-hcc">Edit</b-button>
          </router-link>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :has-navigation="true"
        >
          <!-- For Introduction -->
          <b-step-item
            class="step"
            label="Introduction"
            icon="envelope"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <SectionIntroduction :data="introduction" />
          </b-step-item>

          <!-- For Rules -->
          <b-step-item
            class="step"
            label="Rules"
            icon="pencil-ruler"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <SectionRules :data="rules" />
          </b-step-item>

          <b-step-item
            class="step"
            label="Introduction Test"
            icon="flag"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <SectionIntroductionTest :data="caseStudyIntroductionTest" />
          </b-step-item>

          <b-step-item
            class="step"
            label="Question Test"
            icon="flag"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <SectionQuestionTest :data="caseStudyQuestionTest" />
          </b-step-item>

          <!-- For pagination -->
          <template slot="navigation" slot-scope="{ previous, next }">
            <b-button
              type="is-hcc"
              class="prev"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
              >Prev</b-button
            >

            <b-button
              type="is-hcc"
              @click.prevent="next.action"
              class="button-next"
              :disabled="next.disabled"
              >Next</b-button
            >
          </template>
        </b-steps>
      </div>
    </div>
  </section>
</template>

<script>
import SectionIntroduction from "@/components/SectionDetail/SectionIntroduction";
import SectionRules from "@/components/SectionDetail/SectionRules";
import SectionIntroductionTest from "@/components/SectionDetail/SectionIntroductionTest";
import SectionQuestionTest from "@/components/CaseStudySection/ShowSectionQuestion";

export default {
  components: {
    SectionIntroduction,
    SectionRules,
    SectionIntroductionTest,
    SectionQuestionTest
  },
  data() {
    return {
      activeStep: 0,
      isAnimated: true,
      isProfileSuccess: true,
      isLoading: false,
      caseStudyId: 0,
      introduction: {
        title: "",
        description: ""
      },
      rules: {
        rulesName: "",
        time: 0,
        screenCaptureTime: 10,
        candidatPhotoTime: 10
      },
      caseStudyIntroductionTest: {
        description: ""
      },
      caseStudyQuestionTest: {
        section: [
          {
            title: "",
            question: "",
            answer: ""
          }
        ],
        subsection: [
          {
            title: "",
            question: "",
            answer: ""
          }
        ]
      },
      isEditing: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // For get assessment detail data
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getCaseStudySection",
        this.$route.params.caseStudySectionId
      );
      this.introduction = response.introduction;
      this.rules = response.rules;
      this.caseStudyId = response.caseStudyId;
      this.caseStudyIntroductionTest = response.caseStudyIntroductionTest;
      this.caseStudyQuestionTest = response.caseStudyQuestionTest;

      this.isLoading = false;
    }
  }
};
</script>

<style>
.prev {
  margin-left: 1.5%;
}

.button-next {
  margin-right: 1.5%;
}

.step {
  margin-top: 3%;
}
</style>
