<template>
  <div>
    <h1 class="title has-text-centered">Rules</h1>
    <div>
      <!-- For Rules Name Field -->
      <div class="columns is-12">
        <div class="column is-3">
          <b>Rules Name</b>
        </div>
        <div class="column is-9">{{ data.rulesName }}</div>
      </div>

      <!-- For time -->
      <div class="columns is-12">
        <div class="column is-3">
          <b>Duration Test</b>
        </div>
        <div class="column is-9">{{ data.time }} Seconds</div>
      </div>

      <div class="columns is-12">
        <div class="column is-3">
          <b>Interval Screen Capture Time</b>
        </div>
        <div class="column is-9">{{ data.screenCaptureTime }} Seconds</div>
      </div>

      <div class="columns is-12">
        <div class="column is-3">
          <b>Interval Take Candidat's photo</b>
        </div>
        <div class="column is-9">{{ data.candidatPhotoTime }} Seconds</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
